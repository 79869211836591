import axios from 'axios';
import { apiUrl } from '@/common/apiUrl';

axios.defaults.baseURL = apiUrl;

export default (app) => {
    app.axios = axios;
    app.$http = axios;

    app.config.globalProperties.axios = axios;
    app.config.globalProperties.$http = axios;
}