import api from '@/api';

export default {
  computed: {
    videoExperimentQuery() {
      return this.$route.query.videoexperiment
        ? this.$route.query.videoexperiment == 'true'
        : null;
    },
  },
  mounted() {
    this.getAnonymousFeatureFlags();
  },
  methods: {
    getAnonymousFeatureFlags() {
      // Wait for Clevertap to load to set Clevertap Id as identity string
      const that = this;
      const isClevertapLoaded = setInterval(() => {
        if (
          typeof clevertap.getCleverTapID === 'function'
          && clevertap.getCleverTapID() !== null
        ) {
          console.log('Clevertap Id:', clevertap.getCleverTapID());
          const id = clevertap.getCleverTapID();

          api.get(`/System/FeatureFlagsSet/${id}`).then(({ data }) => {
            that.$store.commit(
              'updateAnonymousFeatureFlags',
              this.overrideFeatureFlags(data),
            );
          });
          clearInterval(isClevertapLoaded);
        }
      }, 500);
    },
    overrideFeatureFlags(data) {
      // Override for testing.
      if (this.videoExperimentQuery === null) {
        return data;
      }

      data.sysFlags['anonymous.VideoExperiment'] = this.videoExperimentQuery;

      return data;
    },
  },
};
