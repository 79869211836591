import { createRouter, createWebHistory } from "vue-router";

import routesSitToStand from "@/router/routesSitToStand";

const SignIn = () => import("@/views/Auth/SignIn.vue");

const Forbidden = () => import("@/views/403.vue");
const PageNotFound = () => import("@/views/404.vue");
const Redirect = () => import("@/views/Redirect.vue");

const AuthMagicLink = () => import("@/views/Auth/AuthMagicLink.vue");
const AuthMagicLinkExpired = () => import("@/views/Auth/AuthMagicLinkExpired.vue");

const AuthMagicLinkConfirm = () =>
  import("@/views/Auth/AuthMagicLinkConfirm.vue");

const NotMyName = () => import("@/views/Auth/NotMyName.vue");
const NotMyNameAgain = () => import("@/views/Auth/NotMyNameAgain.vue");

const routes = [
  {
    path: "/SignIn",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/Magic",
    name: "AuthMagicLink",
    component: AuthMagicLink,

  },
  {
    path: "/Expired",
    name: "AuthMagicLinkExpired",
    component: AuthMagicLinkExpired,

  },
  {
    path: "/Magic/Confirm",
    name: "AuthMagicLinkConfirm",
    component: AuthMagicLinkConfirm,
  },
  {
    path: "/Magic/NotMyName",
    name: "NotMyName",
    component: NotMyName,
  },

  {
    path: "/NotMyNameAgain",
    name: "NotMyNameAgain",
    component: NotMyNameAgain,
  },

  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    name: "404",
  },
  {
    path: "/r",
    component: Redirect,
    name: "Redirect",
  },
  {
    path: "/403",
    name: "403",
    component: Forbidden,
  },
  {
    path: "/",
    name: "Home",
    meta: {
      auth: {
        redirect: "/SignIn",
      },
    },
  },
].concat(routesSitToStand);

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition;
      // For anchors
    }
    if (to.hash) {
      return { selector: to.hash };
      // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    }
    if (from.path === to.path) {
      return {};
    }

    // Scroll to top
    return { left: 0, top: 0 }
  },

  history: createWebHistory(process.env.BASE_URL),

  routes,
});

export default (app) => {
  app.router = router;

  app.use(router);
};
