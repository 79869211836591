<template>
  <div>
    <img
      style="max-width: 6rem"
      class="pulseLogo"
      src="../assets/img/nymbl-flame.svg"
      alt=""
    >
  </div>
</template>

<style lang="scss">
.pulseLogo {
  -webkit-animation: pulseLogo 1s infinite;
  animation: pulseLogo 1s infinite;
}
@keyframes pulseLogo {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}
</style>
