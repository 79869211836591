import axios from 'axios';

export default {
  methods: {
    interceptorsSetup() {
      axios.interceptors.request.use((config) => {
        // Secondary API - NZ/US
        const isSecondaryApi = window.isSecondaryApi
          ? window.isSecondaryApi
          : false;
        const secondaryToken = window.secondaryToken
          ? window.secondaryToken
          : false;
        if (isSecondaryApi) {
          console.log('Is Secondary Api Enabled:', isSecondaryApi);
          config.headers.Authorization = `Bearer ${secondaryToken}`;
        }

        // Impersonating

        if (sessionStorage.getItem('impersonating')) {
          console.log(
            'Is Impersonation Enabled:',
            !!JSON.parse(sessionStorage.getItem('impersonating')),
          );
          config.headers.Authorization = `Bearer ${
            JSON.parse(sessionStorage.getItem('impersonating')).token}`;
        }

        return config;
      });
      axios.interceptors.response.use(
        (response) => {
          this.refreshToken();
          return response;
        },
        (error) => {
          const that = this;
          console.log('Error Main:', error);
          let status = null;
          status = error.response ? error.response.status : null;

          // Set the Error Api flag in Vuex
          that.$store.commit('setApiError', true);
          that.$store.commit('setLoading', false);

          // Unauthorized
          if (status === 401) {
            if (!that.isExpired) {
              that.isExpired = true;
              that.$nMessage.closeAll();
              setTimeout(() => {
                that.$nMessage({
                  type: 'error',
                  message: 'Session has expired. Please log in again.',
                  showClose: true,
                  duration: 5000,
                });
              }, 400);
              that.logout('expire');
            }

            that.$store.commit('setLoading', false);

            return Promise.reject(error);
          }

          if (error.response.config.CUSTOM_ERROR_MESSAGE) {
            that.$nMessage.closeAll();
            that.$nMessage({
              type: 'error',
              message: error.response.config.CUSTOM_ERROR_MESSAGE,
              showClose: true,
              duration: 5000,
            });
            return Promise.reject(error);
          }

          if (status === 404) {
            return Promise.reject(status);
          }

          if (status === 400) {
            return Promise.reject(error);
          }

          // Server Error
          if (error.response && error.response.data.message) {
            that.$nMessage.closeAll();
            that.$nMessage({
              type: 'error',
              message: error.response.data.message,
              showClose: true,
              duration: 5000,
            });
            return Promise.reject(error);
          }
          that.$nMessage.closeAll();

          that.$nMessage({
            type: 'error',
            message: 'API call error',
            showClose: true,
            duration: 5000,
          });

          return Promise.reject(error);
        },
      );
    },
  },
};
