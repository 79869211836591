import axios from 'axios';

export default {
  get(url, params) {
    return axios.get(url, { params });
  },
  post(url, data, params, customErrorMessage) {
    return axios.post(url, JSON.stringify(data), {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
      CUSTOM_ERROR_MESSAGE: customErrorMessage
    });
  },
  put(url, data, params) {
    return axios.put(url, JSON.stringify(data), {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  patch(url, data, params) {
    return axios.patch(url, JSON.stringify(data), {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  delete(url) {
    return axios.delete(url);
  }
};
