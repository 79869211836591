<template>
  <svg
    width="95"
    height="95"
    viewBox="0 0 95 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.9824 5.93774C39.9824 3.6833 38.1548 1.85571 35.9004 1.85571C33.6459 1.85571 31.8184 3.6833 31.8184 5.93774C31.8184 8.19219 33.6459 10.0198 35.9004 10.0198C38.1548 10.0198 39.9824 8.19219 39.9824 5.93774Z"
      fill="#FFCD69"
    />
    <path
      d="M29.6602 36.533C29.6602 34.2785 27.8326 32.4509 25.5781 32.4509C23.3237 32.4509 21.4961 34.2785 21.4961 36.533C21.4961 38.7874 23.3237 40.615 25.5781 40.615C27.8326 40.615 29.6602 38.7874 29.6602 36.533Z"
      fill="#D5EA79"
    />
    <path
      d="M10.0195 71.25C10.0195 68.9956 8.19194 67.168 5.9375 67.168C3.68306 67.168 1.85547 68.9956 1.85547 71.25C1.85547 73.5044 3.68306 75.332 5.9375 75.332C8.19194 75.332 10.0195 73.5044 10.0195 71.25Z"
      fill="#FF7D97"
    />
    <path
      d="M70.3703 27.8498L92.9739 89.5125C93.7647 91.6696 91.6694 93.7648 89.5122 92.9742L27.8495 70.3706C25.999 69.6923 25.4767 67.3234 26.8703 65.9298L65.9297 26.8704C67.3231 25.477 69.692 25.9993 70.3703 27.8498Z"
      fill="#98EAF9"
    />
    <path
      d="M75.123 87.6503L75.2013 87.7286L89.5115 92.9742C91.6686 93.765 93.7638 91.6696 92.9732 89.5124L87.6855 75.0876L75.123 87.6503Z"
      fill="#FF7D97"
    />
    <path
      d="M66.2199 84.0281L84.0282 66.22L77.9168 49.8027L49.8027 77.9167L66.2199 84.0281Z"
      fill="#FFCD69"
    />
    <path
      d="M87.65 74.9884L74.9885 87.6501L66.2197 84.0279L84.028 66.2199L87.65 74.9884Z"
      fill="white"
    />
    <path
      d="M77.9167 49.8022L49.8026 77.9163L40.627 74.7011L74.7015 40.6265L77.9167 49.8022Z"
      fill="white"
    />
    <path
      d="M52.9594 3.6654C52.0545 3.18465 50.9312 3.52847 50.4502 4.43338C44.9254 14.8301 46.2504 27.5127 53.6539 36.5221L50.656 39.52L45.76 34.624C45.0354 33.8994 43.8606 33.8994 43.1358 34.624C42.4113 35.3487 42.4113 36.5232 43.1358 37.2481L48.0318 42.1441L36.0862 54.0898C32.4422 51.3727 28.1621 49.6632 23.6045 49.1242C18.3506 48.5032 13.0952 49.4916 8.40696 51.9831C7.50205 52.4639 7.15842 53.5875 7.63917 54.4922C8.1201 55.3972 9.2434 55.7408 10.1483 55.26C17.6685 51.2637 26.5981 51.8892 33.4303 56.7457L25.5581 64.6179C24.4535 65.7224 23.9952 67.2925 24.3313 68.8181C24.6677 70.3436 25.744 71.5753 27.2108 72.113L88.8737 94.7162C90.5579 95.3335 92.3922 94.9292 93.6606 93.6607C94.5365 92.7847 95.0004 91.6393 95.0004 90.4594C95.0004 89.9306 94.9071 89.395 94.7162 88.8738L72.1127 27.2112C71.5752 25.7445 70.3434 24.6681 68.818 24.3315C67.2924 23.9953 65.7223 24.4538 64.6178 25.5584L56.294 33.882C50.0385 26.0421 48.9655 15.1355 53.7273 6.17454C54.2083 5.26983 53.8643 4.14653 52.9594 3.6654ZM82.1764 65.4474L65.4474 82.1765L52.8022 77.5412L77.5411 52.8023L82.1764 65.4474ZM85.5001 74.5145L74.5144 85.5002L69.2878 83.5842L83.5841 69.2879L85.5001 74.5145ZM91.2318 90.1511C91.393 90.5908 91.1785 90.8945 91.0366 91.0369C90.8945 91.179 90.5905 91.3933 90.151 91.232L78.3549 86.9081L86.9078 78.3551L91.2318 90.1511ZM68.0188 27.9554C68.1871 27.9925 68.4901 28.1111 68.6284 28.4881L72.8096 39.895L62.2562 50.4483C61.5314 51.1728 61.5316 52.3477 62.256 53.0723C62.9805 53.7968 64.1554 53.797 64.88 53.0724L74.2172 43.7354L76.1331 48.962L48.9618 76.1334L43.7351 74.2175L53.0722 64.8802C53.4345 64.518 53.6156 64.043 53.6156 63.5682C53.6156 63.0934 53.4344 62.6184 53.0722 62.2562C52.3476 61.5317 51.1727 61.5317 50.4482 62.2564L39.8949 72.8097L28.4881 68.6284C28.111 68.4902 27.9925 68.1872 27.9552 68.0187C27.9439 67.9675 27.9342 67.9017 27.9342 67.8269C27.9342 67.6564 27.9847 67.4391 28.1823 67.2417L67.2416 28.1824C67.5257 27.8985 67.8506 27.9183 68.0188 27.9554Z"
      fill="black"
    />
    <path
      d="M14.8071 39.0403C14.8071 38.0155 13.9764 37.1848 12.9516 37.1848C11.3976 37.1848 10.1335 35.9205 10.1335 34.3665C10.1335 30.7666 7.20466 27.8375 3.60449 27.8375C2.57972 27.8375 1.74902 28.6684 1.74902 29.693C1.74902 30.7176 2.57972 31.5485 3.60449 31.5485C5.15845 31.5485 6.42258 32.8128 6.42258 34.3665C6.42258 37.9667 9.35144 40.8958 12.9516 40.8958C13.9764 40.8958 14.8071 40.0649 14.8071 39.0403Z"
      fill="black"
    />
    <path
      d="M32.3209 22.5781C32.3209 18.86 29.2959 15.835 25.5779 15.835C23.9061 15.835 22.5459 14.4749 22.5459 12.803C22.5459 11.7784 21.7152 10.9475 20.6904 10.9475C19.6657 10.9475 18.835 11.7784 18.835 12.803C18.835 16.5209 21.8599 19.5459 25.5779 19.5459C27.2497 19.5459 28.6099 20.9062 28.6099 22.5781C28.6099 23.6027 29.4406 24.4336 30.4654 24.4336C31.4902 24.4336 32.3209 23.6027 32.3209 22.5781Z"
      fill="black"
    />
    <path
      d="M35.9004 11.8752C39.1744 11.8752 41.8379 9.21172 41.8379 5.93774C41.8379 2.66377 39.1744 0.000244848 35.9004 0.000244848C32.6264 0.000244848 29.9629 2.66377 29.9629 5.93774C29.9629 9.21172 32.6264 11.8752 35.9004 11.8752ZM33.6738 5.93774C33.6738 4.70998 34.6726 3.71118 35.9004 3.71118C37.1282 3.71118 38.127 4.70998 38.127 5.93774C38.127 7.16551 37.1282 8.16431 35.9004 8.16431C34.6726 8.16431 33.6738 7.16551 33.6738 5.93774Z"
      fill="black"
    />
    <path
      d="M31.5156 36.533C31.5156 33.259 28.8521 30.5955 25.5781 30.5955C22.3042 30.5955 19.6406 33.259 19.6406 36.533C19.6406 39.8069 22.3042 42.4705 25.5781 42.4705C28.8521 42.4705 31.5156 39.8067 31.5156 36.533ZM23.3516 36.533C23.3516 35.3052 24.3504 34.3064 25.5781 34.3064C26.8059 34.3064 27.8047 35.3052 27.8047 36.533C27.8047 37.7607 26.8059 38.7595 25.5781 38.7595C24.3504 38.7595 23.3516 37.7607 23.3516 36.533Z"
      fill="black"
    />
    <path
      d="M11.875 71.25C11.875 67.976 9.21147 65.3125 5.9375 65.3125C2.66353 65.3125 0 67.976 0 71.25C0 74.524 2.66353 77.1875 5.9375 77.1875C9.21147 77.1875 11.875 74.524 11.875 71.25ZM3.71094 71.25C3.71094 70.0222 4.70974 69.0234 5.9375 69.0234C7.16526 69.0234 8.16406 70.0222 8.16406 71.25C8.16406 72.4778 7.16526 73.4766 5.9375 73.4766C4.70974 73.4766 3.71094 72.4778 3.71094 71.25Z"
      fill="black"
    />
    <path
      d="M65.1372 11.0399C64.4126 11.7643 64.4126 12.9388 65.137 13.6641C65.8616 14.3887 67.0364 14.3887 67.761 13.6641L69.5103 11.9148C69.8727 11.5524 70.0538 11.0776 70.0538 10.6028C70.0538 10.128 69.8725 9.65314 69.5103 9.29077C68.7858 8.56621 67.6109 8.56621 66.8863 9.29077L65.1372 11.0399Z"
      fill="black"
    />
    <path
      d="M73.8842 2.2931C73.1597 3.01784 73.1597 4.19235 73.8842 4.91727C74.6088 5.64183 75.7837 5.64183 76.5084 4.91727L78.2578 3.16795C78.6201 2.80558 78.8012 2.33076 78.8012 1.85595C78.8012 1.38114 78.6201 0.906325 78.2578 0.543953C77.5332 -0.180605 76.3583 -0.180605 75.6336 0.543953L73.8842 2.2931Z"
      fill="black"
    />
    <path
      d="M65.1372 3.16795L66.8866 4.91727C67.6111 5.64183 68.786 5.64183 69.5106 4.91727C69.8729 4.5549 70.054 4.08009 70.054 3.60528C70.054 3.13047 69.8728 2.65547 69.5106 2.29328L67.7612 0.543953C67.0367 -0.180605 65.8618 -0.180605 65.1372 0.543953C64.4125 1.26851 64.4127 2.44339 65.1372 3.16795Z"
      fill="black"
    />
    <path
      d="M73.8842 11.9148L75.6336 13.6641C76.3581 14.3887 77.533 14.3887 78.2578 13.6641C78.6201 13.3017 78.8012 12.8269 78.8012 12.3521C78.8012 11.8771 78.6201 11.4025 78.2578 11.0401L76.5084 9.29077C75.7839 8.56621 74.609 8.56621 73.8842 9.29077C73.1597 10.0155 73.1597 11.19 73.8842 11.9148Z"
      fill="black"
    />
    <path
      d="M5.46023 3.60509C5.46023 3.13028 5.27913 2.65528 4.91676 2.2931L3.16742 0.543763C2.44286 -0.180796 1.26798 -0.180796 0.54342 0.543763C-0.18114 1.26851 -0.18114 2.44339 0.54342 3.16795L2.29276 4.91727C3.01732 5.64183 4.1922 5.64183 4.91676 4.91727C5.27913 4.55472 5.46023 4.08009 5.46023 3.60509Z"
      fill="black"
    />
    <path
      d="M14.2065 12.3522C14.2065 11.8772 14.0254 11.4025 13.663 11.0402L11.9137 9.29083C11.1891 8.56627 10.0143 8.56627 9.28951 9.29083C8.56495 10.0156 8.56495 11.1901 9.28951 11.915L11.0388 13.6643C11.7634 14.3889 12.9383 14.3889 13.663 13.6643C14.0254 13.3018 14.2065 12.827 14.2065 12.3522Z"
      fill="black"
    />
    <path
      d="M9.29049 2.2931C8.56593 3.01784 8.56593 4.19235 9.29049 4.91727C10.0151 5.64183 11.1899 5.64183 11.9147 4.91727L13.664 3.16795C14.0264 2.80558 14.2075 2.33076 14.2075 1.85595C14.2075 1.38114 14.0264 0.906325 13.664 0.543953C12.9395 -0.180605 11.7646 -0.180605 11.0398 0.543953L9.29049 2.2931Z"
      fill="black"
    />
    <path
      d="M4.91676 11.9148C5.27913 11.5525 5.46023 11.0776 5.46023 10.6028C5.46023 10.128 5.27913 9.6532 4.91676 9.29083C4.1922 8.56627 3.01732 8.56627 2.29276 9.29083L0.54342 11.0402C-0.18114 11.7645 -0.18114 12.939 0.54342 13.6643C1.26798 14.3889 2.44286 14.3889 3.16742 13.6643L4.91676 11.9148Z"
      fill="black"
    />
    <path
      d="M55.8086 57.6648C55.8086 58.1528 56.0071 58.6315 56.3522 58.9766C56.6974 59.3217 57.1761 59.5203 57.6641 59.5203C58.1521 59.5203 58.6308 59.3217 58.9759 58.9766C59.321 58.6315 59.5195 58.1528 59.5195 57.6648C59.5195 57.1768 59.321 56.6981 58.9759 56.353C58.6308 56.0079 58.1521 55.8093 57.6641 55.8093C57.1761 55.8093 56.6974 56.0079 56.3522 56.353C56.0071 56.6981 55.8086 57.1768 55.8086 57.6648Z"
      fill="black"
    />
    <path
      d="M36.4809 30.594L37.8261 31.9392C38.5507 32.6638 39.7256 32.6638 40.4503 31.9392C40.8127 31.5769 40.9938 31.1021 40.9938 30.6273C40.9938 30.1524 40.8127 29.6776 40.4503 29.3153L39.1051 27.97C38.3805 27.2455 37.2057 27.2455 36.4809 27.97C35.7564 28.6948 35.7564 29.8693 36.4809 30.594Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "ConfettiIcon",
};
</script>
