
//  FallsFree Dashboard

const Dashboard = () => import('@/views/Dashboard.vue');

const Welcome = () => import('@/views/Welcome.vue');

// User Activation
const Activation = () => import('@/views/Activation/Activation.vue');
const Reactivation = () => import('@/views/Activation/Reactivation.vue');
const ReactivationSmartDevice = () => import('@/views/Activation/ReactivationSmartDevice.vue');
const SmartDeviceCheck = () => import('@/views/Activation/SmartDeviceCheck.vue');
const RequestDownloadLink = () => import('@/views/Activation/RequestDownloadLink.vue');
const RequestDownloadLinkConfirm = () => import('@/views/Activation/RequestDownloadLinkConfirm.vue');
const HowToDownload = () => import('@/views/Activation/HowToDownload.vue');
const AppleInstructions = () => import('@/views/Activation/AppleInstructions.vue');
const AndroidInstructions = () => import('@/views/Activation/AndroidInstructions.vue');
const StayActive = () => import('@/views/Activation/StayActive.vue');
const CheckBack = () => import('@/views/Activation/CheckBack.vue')

//  Checkins
const GetReady = () => import('@/views/Checkins/GetReady.vue');

//  Sit-to-Stands
const AboutSitToStands = () => import('@/views/Checkins/SitToStand/AboutSitToStands.vue');
const AboutSitToStands2 = () => import('@/views/Checkins/SitToStand/AboutSitToStands2.vue');
const UnableToComplete = () => import('@/views/Checkins/SitToStand/UnableToComplete.vue');
const Demonstration = () => import('@/views/Checkins/SitToStand/Demonstration.vue');
const SitToStandTest = () => import('@/views/Checkins/SitToStand/SitToStandTest.vue');
const SitToStandInput = () => import('@/views/Checkins/SitToStand/SitToStandInput.vue');
const SitToStandInputConfirm = () => import('@/views/Checkins/SitToStand/SitToStandInputConfirm.vue');
const SitToStandResults = () => import('@/views/Checkins/SitToStand/SitToStandResults.vue');

// Falls Free
const FallsFreeCheck = () => import('@/views/FallsFree/FallsFreeCheck.vue');
const FallsFreeConfirmFall = () => import('@/views/FallsFree/FallsFreeConfirmFall.vue');

const routes = [
  {
    path: '/Dashboard',
    component: Dashboard,
    name: 'Dashboard',
    meta: {
      auth: {
        roles: ['User'],
        forbiddenRedirect: '/403',
      },
      isNavbarDisabled: true,
    },
    children: [
      {
        path: 'Start',
        name: 'Welcome',
        component: Welcome,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
          isNavbarDisabled: true,
        },
      },

      {
        path: 'Test/About',
        name: 'AboutSitToStands',
        component: AboutSitToStands,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/About2',
        name: 'AboutSitToStands2',
        component: AboutSitToStands2,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/Ok',
        name: 'UnableToComplete',
        component: UnableToComplete,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/Activation',
        name: 'Activation',
        component: Activation,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/Reactivation',
        name: 'Reactivation',
        component: Reactivation,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/SmartDeviceCheck',
        name: 'SmartDeviceCheck',
        component: SmartDeviceCheck,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/ReactivationSmartDevice',
        name: 'ReactivationSmartDevice',
        component: ReactivationSmartDevice,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/HowToDownload',
        name: 'HowToDownload',
        component: HowToDownload,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/AppleInstructions',
        name: 'AppleInstructions',
        component: AppleInstructions,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/AndroidInstructions',
        name: 'AndroidInstructions',
        component: AndroidInstructions,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/RequestDownloadLink',
        name: 'RequestDownloadLink',
        component: RequestDownloadLink,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/RequestDownloadLinkConfirm',
        name: 'RequestDownloadLinkConfirm',
        component: RequestDownloadLinkConfirm,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/StayActive',
        name: 'StayActive',
        component: StayActive,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/CheckBack',
        name: 'CheckBack',
        component: CheckBack,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/Demonstration',
        name: 'Demonstration',
        component: Demonstration,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/GetReady',
        name: 'GetReady',
        component: GetReady,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },
      {
        path: 'Test/Standups',
        name: 'SitToStandTest',
        component: SitToStandTest,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
        },
      },

      {
        path: 'Test/Input',
        name: 'SitToStandInput',
        component: SitToStandInput,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
          isNavbarDisabled: true,
        },
      },
      {
        path: 'Test/InputConfirm',
        name: 'SitToStandInputConfirm',
        component: SitToStandInputConfirm,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
          isNavbarDisabled: true,
        },
      },
      {
        path: 'Test/Results',
        name: 'SitToStandResults',
        component: SitToStandResults,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
          isNavbarDisabled: true,
        },
      },

     
      {
        path: 'Test/CheckIn',
        name: 'FallsFreeCheck',
        component: FallsFreeCheck,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
          isNavbarDisabled: true,
        },
      },
      {
        path: 'Test/ConfirmFall',
        name: 'FallsFreeConfirmFall',
        component: FallsFreeConfirmFall,
        meta: {
          auth: {
            roles: ['User'],
            forbiddenRedirect: '/403',
          },
          isNavbarDisabled: true,
        },
      },
    ],
  },
];

export default routes;
