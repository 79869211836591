import { mixin as VueTimers } from 'vue-timers';
import moment from 'moment-mini';

export default {
  mixins: [VueTimers],
  timers: {
    isActive: { time: 10000, autostart: true, repeat: true },
  },
  data() {
    return {
      lastClick: null,
    };
  },
  created() {
    this.addClickEventListener();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.recordClick);
  },
  methods: {
    isActive() {
      // Don't expire if Remember Me selected
      if (
        !this.$store.getters.hasValidRememberMeToken
        && this.lastClick
        && !this.lastClick.isAfter(moment().subtract(20, 'minutes'))
      ) {
        this.handleExpire();
        return;
      }
      if (this.loggedIn) {
        this.refreshToken();
      }
    },
    addClickEventListener() {
      document.addEventListener('click', this.recordClick);
    },

    recordClick() {
      this.lastClick = moment();
    },
    handleExpire() {
      if (this.$auth.check()) {
        console.log('Session Expired.');
        this.$nMessage.closeAll();
        this.$nMessage({
          type: 'error',
          message: 'Session has expired. Please log in again.',
          showClose: true,
          duration: 5000,
        });
        this.logout('expire');
        this.lastClick = null;
      }
    },
  },
};
