<template>
  <div>
    <router-view />
    <div
      v-show="loaded && !isError"
      class="max-w-2xl m-auto md:rounded md:shadow px-4 py-4 md:px-10 md:py-5 bg-white text-sm md:text-base my-14"
      style="min-height: 500px"
    >
      <img
        class="w-20 md:w-32"
        src="../../assets/img/nymblscience-logo.svg"
      >
      <div>
        <h1 class="text-center font-bold text-sm md:text-lg mb-3 mt-4">
          Communication Preferences
        </h1>
        <p class="text-gray-390 text-center mb-6">
          Select which lists you would like to receive communications from:
        </p>

        <div
          v-for="channel in Object.keys(subscriptionGroupsByChannel)"
          :key="channel"
          class="mb-3 md:mb-4"
        >
          <h2 class="font-bold mb-2">
            {{ channel }}
          </h2>
          <div
            v-for="(group, index) in subscriptionGroupsByChannel[channel]"
            :key="group.name"
            class="mb-3 md:mb-4"
          >
            <div
              class="cursor-pointer flex p-3 md:p-5 transition-all"
              :style="
                index % 2 === 0 ? 'background: #fafafa ' : ' background: #fff'
              "
              @click="group.isSubscribed = !group.isSubscribed"
            >
              <span>{{ group.name }}</span>
              <n-checkbox
                v-model="group.isSubscribed"
                class="ml-auto"
              />
            </div>
          </div>
        </div>

        <div class="text-right">
          <n-button
            class="mt-2 md:mt-6"
            :loading="loading"
            @click="changeSubscriptionGroups()"
          >
            Save
          </n-button>
        </div>
      </div>
    </div>

    <full-screen-loading
      v-if="!loaded"
      is-logo
    />

    <div
      v-if="isError"
      class="h-screen w-screen flex justify-center items-center flex-col text-center"
    >
      <img
        style="max-width: 8rem"
        class="logo mx-auto mb-6"
        src="../../assets/img/nymblscience-logo.svg"
      >

      <Headline>Something went wrong</Headline>
      <Paragraph class="mt-4">
        Contact support@nymblscience.com
      </Paragraph>
    </div>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";

import FullScreenLoading from "@/components/FullScreenLoading.vue";
import Headline from "@/components/Headline.vue";
import Paragraph from "@/components/Paragraph.vue";
import axios from "axios";
export default {
  components: {
    FullScreenLoading,
    Headline,
    Paragraph,
  },
  data() {
    return {
      subscriptionGroups: [],
      loading: false,
      loaded: false,
      isError: false,
    };
  },

  computed: {

    subscriptionGroupsByChannel() {
      return groupBy(this.subscriptionGroups, "channel");
    },
    internalId() {
      return this.$route.query.internalId;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  watch: {
    // Get subscription groups when internalId changes and router is ready
    $route() {
      if (!this.internalId) {
        this.isError = true
        this.loaded = true
        return
      }
      this.getSubscriptionGroups();
    },
  },
  mounted() {
    document.title = "Nymbl - Communication preferences";

  },
  created() {
    // Add temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.add("preference-center");
    body.classList.add("preference-center");

    this.removeSplashScreen();
  },

  methods: {
    getSubscriptionGroups() {
      this.loading = true;
      axios
        .get(`${this.apiUrl}/User/${this.internalId}/SubscriptionGroups`)
        .then((result) => {
          this.subscriptionGroups = result.data.map((group) => {
            return {
              ...group,
              isSubscribed: group.status === "Subscribed" ? true : false,
            };
          });
        })
        .catch((err) => {
          this.isError = true;
          console.log(err);
        }).finally(() => {
          this.loaded = true;
          this.loading = false;
        });
    },
    changeSubscriptionGroups() {
      const subscriptionGroupsFlat = Object.keys(
        this.subscriptionGroupsByChannel
      )
        .map((groupKey) => {
          const group = this.subscriptionGroupsByChannel[groupKey];
          return group;
        })
        .flat();

      const subscriptionGroups = subscriptionGroupsFlat.map((group) => {
        return {
          name: group.name,
          status: group.isSubscribed ? "Subscribed" : "Unsubscribed",
          id: group.id,
          channel: group.channel,
        };
      });

      this.loading = true;
      axios
        .put(
          `${this.apiUrl}/User/${this.internalId}/SubscriptionGroups`,
          subscriptionGroups
        )
        .then(() => {
          this.getSubscriptionGroups();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    removeSplashScreen() {
      // Remove Nymbl splash screen when vue is loaded.
      const splashScreen = document.getElementById("splash-screen");

      if (document.contains(splashScreen)) {
        splashScreen.classList.add("fade-out");
        setTimeout(() => {
          splashScreen.remove();
        }, 600);
      }

      setTimeout(() => {
        if (document.contains(document.getElementById("splash-screen-style"))) {
          document.getElementById("splash-screen-style").remove();
        }
      }, 4000);
    },
  },
};
</script>

<style>
.preference-center {
  font-size: 24px !important;
  padding: 0 !important;
}
</style>
