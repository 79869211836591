<template>
  <div
    v-if="isAuthReady"
    class="auth-container bg-white h-full"
  >
    <router-view @logout="logout" />
  </div>
</template>

<script>
import sessionExpire from "@/sessionExpire";
import refreshToken from "@/refreshToken";
import anonymousFeatureFlags from "@/anonymousFeatureFlags";
import axiosSetup from "@/axiosSetup";
import api from "@/api";
import axios from "axios";
import { logout } from "@/auth";
export default {
  name: "App",
  mixins: [axiosSetup, sessionExpire, refreshToken, anonymousFeatureFlags],
  data() {
    return {
      loaded: false,
      navbarShow: true,
      restrictedError: false,
      isTokenRefreshing: false,
      window: {
        width: 0,
        height: 0,
      },
      user: [],
      companyName: "",
      isExpired: false,
    };
  },
  computed: {
    isAuthReady() {
      return this.$auth.ready();
    },
    me() {
      return this.$store.state.me;
    },
    loggedIn() {
      return this.$auth.check();
    },
    loggedInStore() {
      return false;
    },
    activeApi() {
      return this.$store.state.activeApi;
    },
    companyNameComputed() {
      if (this.me && Object.keys(this.me).length === 0) {
        return "";
      }
      if (this.me.companies && this.me.companies.length > 0) {
        return this.me.companies[0].company.name;
      }
      return "No Company";
    },

    device() {
      const detectDeviceType = () =>
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? "is-mobile"
          : "is-desktop";

      return detectDeviceType();
    },
    scrollbarWidth() {
      return window.innerWidth - document.documentElement.clientWidth;
    },
    route() {
      return this.$route;
    },
  },
  watch: {
    // Don't allow $store.state.me to be empty if authenticated.
    loggedIn(isLoggedIn) {
      console.log("Logged In: ", isLoggedIn);

      if (this.loggedIn) {
        axios.get("/Me").then((response) => {
          this.$store.commit("updateMe", response.data);
        });
      }
      if (isLoggedIn && Object.keys(this.me).length === 0) {
        this.getDefaults();
      }
    },
    // Log out from all tabs
    loggedInStore(isLoggedIn) {
      console.log("Is Logged in Store:", isLoggedIn);
      if (this.loggedIn && !isLoggedIn) {
        console.log("Logging out of all tabs");
        this.logout("All Tabs");
      }
    },
  },
  mounted() {
    this.removeSplashScreen();

    this.handleResize();

    this.getAnonymousDefaults();
  },
  beforeUnmount() {
    // Remove temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.remove("new-ui-fallsfree");
    body.classList.remove("new-ui-fallsfree");
  },
  created() {
    // Add temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.add("new-ui-fallsfree");
    body.classList.add("new-ui-fallsfree");

    this.interceptorsSetup();
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.$store.commit("updateWindow", this.window);
    },

    logout(context) {
      logout(context, this.$store, this.$auth, this.$route).then(() => {
        this.getAnonymousDefaults();
        this.$store.commit("setLoading", false);
        this.$router.push({ name: "SignIn" });
      });
    },

    getDefaults() {
      const that = this;
      api.get("System/Defaults").then(({ data }) => {
        that.$store.commit("updateDefaults", data);
      });
    },

    getAnonymousDefaults() {
      const that = this;
      api.get("/System/AnonymousDefaults").then(({ data }) => {
        that.$store.commit("updateAnonymousDefaults", data);
      });
    },

    removeSplashScreen() {
      // Remove Nymbl splash screen when vue is loaded.
      const splashScreen = document.getElementById("splash-screen");

      // Don't remove if /InApp route
      if (
        window.location.pathname === "/InApp" ||
        window.location.pathname === "/InApp/authcode"
      ) {
        return;
      }

      if (document.contains(splashScreen)) {
        splashScreen.classList.add("fade-out");
        setTimeout(() => {
          splashScreen.remove();
        }, 600);
      }

      setTimeout(() => {
        if (document.contains(document.getElementById("splash-screen-style"))) {
          document.getElementById("splash-screen-style").remove();
        }
      }, 4000);
    },
  },
};
</script>
<style lang="scss">
.new-ui-fallsfree {
  color: #333 !important;
  font-size: 24px !important;
  letter-spacing: -0.17px !important;
  padding-bottom: 0;
  min-height: 100%;
  // Add 1px height to trick min-height 100&
  height: 1px;
  #app {
    min-height: 100%;
    height: 1px;
  }
  .auth-container {
    min-height: 100%;
    height: 1px;
  }
  .n-button {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 24px;
    font-weight: 700;
    border-radius: 4px !important;
    &__content {
      overflow-wrap: break-word !important;
      white-space: normal !important;
    }
  }
  .n-textbox .n-textbox__label-text {
    letter-spacing: -0.17px !important;
    color: #000 !important;
  }
  .n-textbox__input {
    letter-spacing: 1.5px !important;
  }
  .n-textbox__input::placeholder {
    font-style: italic !important;
    letter-spacing: 1.5px;
    color: #828282 !important;
  }
  .n-button__primary.is-disabled {
    color: #fff !important;
    background: #bdbdbd !important;
    box-shadow: none !important;
    opacity: 1;
  }
  .n-button__transparent .n-loading-circle.n-button__loading circle {
    stroke: #0c6a69;
  }
}
#nymbl-logo {
  max-width: 4.5rem;
  @media (min-width: 30rem) {
    max-width: 6rem;
  }
}
.fallsfree-container {
  @media (max-width: 40rem) {
    min-height: 80vh;
  }
  @media (min-width: 50rem) {
    min-height: 37rem;
    max-width: 60rem;
  }
}
</style>
