import { useSitToStandStore } from "@/stores/sitToStand";

const logout = (context, store, auth, route) => {
  console.log("logout context:", context);

  if (context !== "SignIn") {
    // Restore VUEX to the original state
    store.commit("resetState");
  }

  // Reset pinia stores
  const sitToStandStore = useSitToStandStore();
  sitToStandStore.$reset()

  // Remember the route when session expired
  if (context === "expire") {
    store.commit("updateRedirectPathExpire", route);
  }

  // Logout from other tabs
  store.commit("updateLoginStatus", false);

  // Clear The local storage from tokens and expiry dates
  localStorage.clear();
  sessionStorage.clear();

  return auth.logout({
    success() {
      console.log("Logged out successfully");
    },
    error() {
      console.log("Error while logging out");
    },
  });
};

export { logout };
