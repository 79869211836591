// export default {
//   request(req, token) {
//     this.http.setHeaders.call(this, req, {
//       Authorization: `Bearer ${token}`,
//     });
//   },

//   response(res) {

//   },
// };
export default {
  request: function (req, token) {
    this.drivers.http.setHeaders.call(this, req, {
      Authorization: "Bearer " + token,
    });
  },

  response: function (res) {
    if (
      res.data &&
      res.data.access_token &&
      res.data.refresh_token &&
      res.data.expires_in
    ) {
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      localStorage.setItem("expires", res.data[".expires"]);
      return res.data.access_token;
    }
    // console.log(res);
    return null;
  },
};
