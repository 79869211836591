import { createApp } from "vue";
import { createPinia } from "pinia";
import { createRouter, createWebHistory } from "vue-router";

import VueGtag from "vue-gtag";
import NymblUI from "nymbl-ui";

import router from "./router";
import http from "./plugins/http";
import App from "./App.vue";
import CommunicationsClevertap from "@/views/Communications/CommunicationsClevertap.vue";
import Communications from "@/views/Communications/Communications.vue";

import auth from "./plugins/auth.js";

import { store } from "./store.js";

// Import Nymbl Vue UI kit from organization GitHub npm package.
import "nymbl-ui/dist/nymbl-ui.css";

// Import Tailwind
import "@/assets/tailwind.css";

// A communication preference center. Powered by Clevertap.
// preferences.fallsfree.com
const createCommunicationAppClevertap = () => {
  let app = createApp(CommunicationsClevertap);
  const { Components, Plugins } = NymblUI;

  Object.keys(Components).forEach((name) => {
    app.component(name, Components[name]);
  });

  app.use(Plugins.nconfirm);
  app.use(Plugins.nmessage);

  // Create simple placeholder router
  // Router is needed for this.$route functions.
  const Home = { template: "<div></div>" };
  const routes = [{ path: "/", component: Home }];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  app.use(router);
  app.mount("#app");
};

// A communication preference center. Currently powered by Braze.
// With Nymbl API wrapper
// communications.fallsfree.com
const createCommunicationApp = () => {
  let app = createApp(Communications);
  const { Components, Plugins } = NymblUI;

  Object.keys(Components).forEach((name) => {
    app.component(name, Components[name]);
  });

  app.use(Plugins.nconfirm);
  app.use(Plugins.nmessage);

  // Create simple placeholder router
  // Router is needed for this.$route functions.
  const Home = { template: "<div></div>" };
  const routes = [{ path: "/", component: Home }];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  app.use(router);
  app.mount("#app");
};


// Member Portal
// portal.benymbl.com
const createMemberPortal = () => {
  let app = createApp(App);

  app.use(http);
  app.use(router);
  app.use(store);
  app.use(createPinia());

  // Google Analytics

  app.use(VueGtag, {
    config: {
      id:
        process.env.VUE_APP_ENV === "production"
          ? "G-PPLBXGHG7Z"
          : "G-PR9QD1DLFL",
    },
  });

  const { Components, Plugins } = NymblUI;

  Object.keys(Components).forEach((name) => {
    app.component(name, Components[name]);
  });

  app.use(Plugins.nconfirm);
  app.use(Plugins.nmessage);

  app.use(auth);

  app.mount("#app");

  window.App = app;
};


// Determine which app to load based on the host.
const host = window.location.host.split(".");

if (host[0] === "preferences") {
  createCommunicationAppClevertap();
} else if (host[0] === "communications") {
  createCommunicationApp();
} else {
  createMemberPortal();
}
