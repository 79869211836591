import { defineStore } from "pinia";
import api from "@/api";
import moment from "moment-mini";
export const useSitToStandStore = defineStore("sitToStand", {
  state: () => {
    return {
      onDemandPartResponse: {},
      onDemandSessionFeedbackPartResponse: {},
      nymblSessionCurrent: {},
      standupCountEntered: null,
    };
  },
  actions: {
    getCurrentNymblSession() {
      const data = {
        // Offset returned in minutes
        utcOffset: moment().utcOffset() / 60,
        softwareVersion: "P1.01",
        trainingLevelCode: "OnDemandCheckIn",
      };

      return api
        .post(`/Me/NymblSessions/Current`, {}, data)
        .then(({ data }) => {
          this.nymblSessionCurrent = data;
        });
    },
    async saveCheckIn() {
      await this.getCurrentNymblSession();
      const checkIn = {
        NymblSessionId: this.nymblSessionCurrent.id,
        ActivityAt: moment().format(),
        CheckInCode: "ME_001_X",
        IsSkipped: true,
        ActualDuration: 30,
        ActualReps: this.standupCountEntered,
        TerminationCode: 0,
        TrainingLevelCode: "OnDemandCheckIn",
      };
      return api.post(`/Me/NymblSessions/CheckIn`, checkIn).then(({ data }) => {
        this.onDemandPartResponse = data.onDemandPartResponse;
      });
    },
    goToDeepLink() {
      let url = `https://${
        process.env.VUE_APP_ENV === "production" ? "my" : "test"
      }.nymblscience.com/InApp`;
      window.location.href = url;
    },
  },
});
