import { createAuth } from "@websanova/vue-auth/src/v3.js";
import driverHttpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";

import authBearer from "../authDriver.js";

export default (app) => {
  app.use(
    createAuth({
      plugins: {
        http: app.axios,
        router: app.router,
      },
      drivers: {
        http: driverHttpAxios,
        auth: authBearer,
        router: driverRouterVueRouter,
      },
      options: {
        rolesKey: "roles",
        notFoundRedirect: { name: "404" },
        loginData: {
          url: "token",
          method: "POST",
          redirect: "/",
          fetchUser: true,
        },
        rolesVar: "roles",

        authRedirect: { path: "/SignIn" },
        logoutData: {
          url: "",
          method: "POST",
          redirect: false,
          makeRequest: false,
        },
        fetchData: {
          url: "ME",
          method: "GET",
          enabled: true,
          success() {},
        },
        refreshData: {
          url: "/token",
          method: "POST",
          enabled: false,
          interval: 1,
        },
        parseUserData(data) {
          return data;
        },
        tokenStore: ["storage"],
      },
    })
  );
};
