<template>
  <div class="h-screen flex justify-center md:items-center">
    <router-view />
    <div
      class="max-w-2xl m-auto md:rounded md:shadow px-4 py-4 md:px-10 md:py-5 bg-white text-sm md:text-base"
    >
      <img
        class="w-20 md:w-32"
        src="../../assets/img/nymblscience-logo.svg"
      >
      <transition name="fade">
        <div v-if="!isSuccessPage">
          <h1 class="text-center font-bold text-sm md:text-lg mb-3">
            Email Preferences
          </h1>
          <p class="text-gray-390 text-center mb-6">
            Select which lists you would like to receive email communications
            from:
          </p>
          <p
            class="text-sm md:text-base font-bold mt-2 md:mt-6 mb-2 pl-3 md:pl-5"
          >
            Email Address:
          </p>
          <p
            id="email"
            class="text-sm md:text-base mb-3 md:mb-8 pl-3 md:pl-5"
            type="text"
          />
  
          <!-- Displaying unsubscribe groups in the below div once loaded -->
          <div
            v-for="(group, index) in subscriptionGroups"
            :key="group.name"
            class="mb-3 md:mb-4"
          >
            <div
              class="cursor-pointer flex p-3 md:p-5 transition-all"
              :style="
                index % 2 === 0 ? 'background: #fafafa ' : ' background: #fff'
              "
              @click="group.isSubscribed = !group.isSubscribed"
            >
              <span>{{ group.name }}</span>
              <n-checkbox
                v-model="group.isSubscribed"
                class="ml-auto"
              />
            </div>
          </div>
          <!-- Unsubscribe groups buttont. User will click here and it should call `changeSubscriptionGroups`
          method to send the request to CleverTap. -->
          <div class="text-right">
            <n-button
              class="mt-2 md:mt-6"
              @click="changeSubscriptionGroups()"
            >
              Save
            </n-button>
          </div>
        </div>
        <div
          v-else
          class="px-20 py-10 text-center"
        >
          <Confetti
            class="md:block md:w-14 m-auto mb-6"
            style="transform: scaleX(-1)"
          />
          <h1 class="md:text-lg font-bold text-center mb-6">
            Thank you!
          </h1>
  
          <p class="text-center">
            Email preferences updated successfully.
          </p>
          <n-button
            class="mt-6"
            @click="goToPreferences()"
          >
            Back to preferences
          </n-button>
        </div>
      </transition>
    </div>
  </div>
</template>
  
  <script>
  /*eslint-disable*/
  import Confetti from "@/components/Confetti.vue";
  
  export default {
    components: {
      Confetti,
    },
    data() {
      return {
        subscriptionGroups: [],
        isSuccessPage: false,
      };
    },
  
    computed: {
      successQuery() {
        return this.$route.query.success;
      },
    },
    mounted() {
      document.title = "Nymbl - Communication preferences";
    },
    created() {
      // Add temporary classes to complement Tailwind CSS
      const root = document.getElementsByTagName("html")[0];
      const body = document.body;
      root.classList.add("preference-center");
      body.classList.add("preference-center");
  
      const that = this;
      this.removeSplashScreen();
      window.onload = function () {
        var isReEncode = false; //Should be true only if your server is url encoding the URL on unsubscribe landing page
        var withGroups = true; // Should be true if the unsubscribe groups should be displayed on the landing page.
        $WZRK_WR.getEmail(false, withGroups);
      };
  
      // will be called after the email id of the user has been fetched
      window.wzrk_email_fetched = (emailStr, profile) => {
        document.getElementById("email").innerText = emailStr;
        if (!profile || !profile.groups || profile.groups.length === 0) {
          console.log("did not recv groups in callback");
        }
        var subscriptionGroups = profile.groups; // will be recvd only if withGroups passed true above
        $WZRK_WR.setSubscriptionGroups(subscriptionGroups); // Can also be set here and can be retrieved by calling `$WZRK_WR.getSubscriptionGroups` method
        $WZRK_WR.setUpdatedCategoryLong(profile);
        displayGroups();
      };
      function displayGroups() {
        //   Fetching groups
        var subscriptionGroups = $WZRK_WR.getSubscriptionGroups();
  
        // Change order so checkboxes are right value
        that.subscriptionGroups = subscriptionGroups.map((group) => {
          group.isSubscribed = !group.isUnsubscribed;
          return group;
        });
      }
  
      window.wzrk_email_subscription = (status) => {
        this.updateQuery({ success: true });
      };
    },
    watch: {
      successQuery(successQuery) {
        if (successQuery) {
          this.isSuccessPage = true;
        } else {
          this.goToPreferences();
        }
      },
    },
    methods: {
      changeSubscriptionGroups() {
        const subscriptionGroups = [...this.subscriptionGroups].map((group) => {
          group.isUnsubscribed = !group.isSubscribed;
          return group;
        });
        console.log(subscriptionGroups);
  
        // If there are no subscribtion groups selected, CT expects to unsubscribe completely as well.
        if (subscriptionGroups.every((group) => group.isUnsubscribed)) {
          console.log("Unsubscribing from all");
          $WZRK_WR.unSubEmail(false);
        } else {
          console.log("Subscribing to all");
          $WZRK_WR.subEmail(false);
        }
  
        $WZRK_WR.changeSubscriptionGroups(false, subscriptionGroups);
      },
      goToPreferences() {
        this.removeQuery("success");
        const url = window.location.href;
        window.location.href = url;
      },
      removeSplashScreen() {
        // Remove Nymbl splash screen when vue is loaded.
        const splashScreen = document.getElementById("splash-screen");
  
        if (document.contains(splashScreen)) {
          splashScreen.classList.add("fade-out");
          setTimeout(() => {
            splashScreen.remove();
          }, 600);
        }
  
        setTimeout(() => {
          if (document.contains(document.getElementById("splash-screen-style"))) {
            document.getElementById("splash-screen-style").remove();
          }
        }, 4000);
      },
      updateQuery(query) {
        this.$router.push({
          query: { ...this.$route.query, ...query },
        });
      },
      removeQuery(queryToRemove) {
        const query = Object.assign({}, this.$route.query);
        if (!query[queryToRemove]) {
          return;
        }
        delete query[queryToRemove];
        this.$router.replace({ query });
      },
    },
  };
  </script>
  
  <style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .preference-center {
    font-size: 24px !important;
    padding: 0 !important;
  }
  </style>
  